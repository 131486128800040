/**
 * Tests Identifiers
 */
export const TEST_IDS = {
  panel: {
    audio: 'data-testid panel audio',
    buttonDownload: 'data-testid panel button-download',
    buttonNext: 'data-testid panel button-next',
    buttonPrevious: 'data-testid panel button-previous',
    buttonZoom: 'data-testid panel button-zoom',
    buttonZoomPanPinchIn: 'data-testid panel button-zoom-pan-pinch-in',
    buttonZoomPanPinchOut: 'data-testid panel button-zoom-pan-pinch-out',
    buttonZoomPanPinchReset: 'data-testid panel button-zoom-pan-pinch-reset',
    iframe: 'data-testid panel iframe',
    image: 'data-testid panel image',
    imageLink: 'data-testid panel image-link',
    root: 'data-testid panel',
    video: 'data-testid panel video',
    warning: 'data-testid panel warning',
    zoomedImage: 'data-testid panel zoomed-image',
  },
};
